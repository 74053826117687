.column-portfolio-1 {
  flex: 100% !important;
  padding: 4px 4px;
  width: 40% !important;
  vertical-align: middle;
  margin: 10px;
  margin-inline: 0 !important;
}
.column-portfolio-2 {
  flex: 50% !important;
  padding: 4px 4px;
  width: 40% !important;
  vertical-align: middle;
  margin: 10px;
  margin-inline: 0 !important;
}
.column-portfolio-3 {
  flex: 32% !important;
  padding: 4px 4px;
  width: 40% !important;
  vertical-align: middle;
  margin: 10px;
  margin-inline: 0 !important;
}
.column-portfolio-4 {
  flex: 24% !important;
  padding: 4px 4px;
  width: 40% !important;
  vertical-align: middle;
  margin: 10px;
  margin-inline: 0 !important;
}

.column-portfolio img {
  margin: 8px 8px;
  vertical-align: middle;
  border-radius: 10px;
}

.display-flex {
  display: flex;
}
