.StoricoOrdiniComponente-inline {
  display: inline-flex;
}

.StoricoOrdiniComponente-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.StoricoOrdiniComponente-box {
  width: 49%;
  height: 200px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.StoricoOrdiniComponente-box-fWidth {
  width: 100%;
  min-height: 220px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.StoricoOrdiniComponente-box-title {
  text-align: center;
  padding: 10px;
}
.StoricoOrdiniComponente-box-title h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.StoricoOrdiniComponente-box-content {
  text-align: center;
}
.StoricoOrdiniComponente-box-content-number {
  font-size: 32px !important;
  font-weight: bold;
}
.StoricoOrdiniComponente-box-content-subtitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px !important;
}
.StoricoOrdiniComponente-box-content-alerts {
  margin-top: 10px;
  padding: 10px;
}
.StoricoOrdiniComponente-box-content-alerts-value {
  padding: 10px;
  font-size: 22px !important;
}
