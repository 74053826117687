.AttivitaComp-inline{
  display: inline-flex;
}

.AttivitaComp-row{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.AttivitaComp-box{
  width: 45%;
  height: 200px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
}
.AttivitaComp-box-title{
  text-align: center;
  padding: 10px;
}
.AttivitaComp-box-title h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.AttivitaComp-box-content{
  text-align: center;
}
.AttivitaComp-box-content-number{
  font-size: 32px !important;
  font-weight: bold;
}
.AttivitaComp-box-content-subtitle{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px !important;
}
.AttivitaComp-box-content-alerts{
  margin-top: 10px;
  padding: 10px;
}
.AttivitaComp-box-content-alerts-value{
  padding: 10px;
  font-size: 22px !important;
}