.tableComponent-item {
  --border-radius: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.tableComponent-flex {
  display: flex;
  align-items: center;
}
.tableComponent-margin-top {
  margin-top: 5px;
}
.tableComponent-time {
  position: absolute;
  left: 10px;
  top: 10px;
}
.tableComponent-users {
  position: absolute;
  top: 10px;
  left: 10px;
}
