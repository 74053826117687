.row-portfolio {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: space-around;
}
.column-portfolio {
  flex: 50% !important;
  padding: 4px 4px;
  width: 40% !important; 
  vertical-align: middle;
  margin: 10px;
  margin-inline: 0 !important;
  
}

.column-portfolio img {
  margin: 8px 8px;
  vertical-align: middle;
  border-radius: 10px;
}
