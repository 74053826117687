.inventario-column-container {
  width: 100%;
  max-height: 90vh;
  position: relative;
}
.inventario-content {
  max-height: 80vh;
  overflow: auto;
}
.inventario-column-items {
  height: 100%;
}
